<template>
    <el-row class="hz-row" :gutter="24">
        <el-col :span="12">
            <el-button type="primary" @click="addDialog('')">
                <el-icon style="vertical-align: middle">
                    <Plus />
                </el-icon>
                <span style="vertical-align: middle"> 新增分类 </span>
            </el-button>
        </el-col>
        <el-col :span="4">
        </el-col>
        <el-col :span="8">
            <el-row :gutter="10">
                <el-col :span="8">
                    <el-input v-model="searchName" placeholder="输入分类名称"></el-input>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="init()">
                        <el-icon style="vertical-align: middle">
                            <Search />
                        </el-icon>
                        <span style="vertical-align: middle"> 搜索 </span>
                    </el-button>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
    <el-divider></el-divider>
    <div>
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border>
            <el-table-column prop="name" label="分类名称" />
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button size="small" type="primary" v-if="(scope.row.parentId == '0' ? true : false)"
                        @click="addDialog(scope.row)">新增子节点</el-button>
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
  
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            small: false,
            background: true,
            tableData: [],
            total: 0,
            dialogVisible: false,
            dialogTitle: '新增账号',
            searchName: ''
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            let _this = this;
            this.$https.get('api/admin/Category/GetCatetree').then(res => {
                if (res.success) {
                    _this.tableData = res.data
                }
            })
        },
        handleEdit(index, item) {
            const _this = this;
            ElMessageBox.prompt('请输入分类名称', '提示', {
                confirmButtonText: '保存',
                cancelButtonText: '取消',
                inputPattern: !/^s*$/,
                inputErrorMessage: '分类名称不能为空',
                inputValue: item.name
            }).then(({ value }) => {
                this.$https.post('api/admin/category/InsertOrUpdate', {
                    id: item.id,
                    cateName: value,
                    pid: item.parentId,
                    isVisible: 1
                }).then(res => {
                    if (res.success) {
                        ElMessage({
                            type: 'success',
                            message: res.msg
                        })
                        _this.init();
                    }
                });
            }).catch(() => {

            })
        },
        handleDelete(index, item) {
            const _this = this;
            this.$https.get('api/admin/Category/del', { params: { id: item.id } }).then(res => {
                if (res.success) {
                    ElMessage({
                        type: 'success',
                        message: res.msg
                    })
                    _this.init();
                }
            })
        },
        //点击页码事件
        handleCurrentChange(value) {
            this.currentPage = value
            this.init();
        },
        //弹出新增账号界面
        addDialog(item) {
            const _this = this;
            ElMessageBox.prompt('请输入分类名称', '提示', {
                confirmButtonText: '保存',
                cancelButtonText: '取消',
                inputPattern: !/^s*$/,
                inputErrorMessage: '分类名称不能为空',
            }).then(({ value }) => {
                this.$https.post('api/admin/category/InsertOrUpdate', {
                    CateName: value,
                    pid: (item != '' ? item.id : 0),
                    isVisible: 1
                }).then(res => {
                    if (res.success) {
                        ElMessage({
                            type: 'success',
                            message: res.msg
                        })
                        _this.init();
                    }
                });
            }).catch(() => {

            })
        },
        //关闭弹窗
        closeDialog() {
            this.dialogVisible = false;
            this.init();
        }
    },
    components: {
    }
}
</script>
  
<style>
.pagin {
    margin-top: 10px;
}

.el-dialog__body {
    padding-top: 0 !important;
}
</style>